body {
    margin: 0;
    font-family: 'Press Start 2P', cursive;
  }
  
  .App {
    text-align: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
  }

  h1 {
    font-family: 'Sixtyfour', cursive;
    font-size: 3.5rem;
    margin-bottom: 0.5rem;
    letter-spacing: -1px;
    text-transform: uppercase;
  }
  
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .content {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 2rem;
    border-radius: 10px;
  }
  
  h1 {
    font-size: 3rem;
    margin-bottom: 0.5rem;
  }
  
  p {
    font-size: 1rem;
  }
  
  .description {
    font-family: 'Pacifico', cursive;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .game h2 {
    font-family: 'Orbitron', sans-serif;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  
  .snake-game {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .game-console {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 20px;
    backdrop-filter: blur(5px);
  }
  
  canvas {
    border: 2px solid #00f2ff;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 10px #00f2ff;
  }
  
  .game-info {
    margin-top: 20px;
    color: #00f2ff;
    font-family: 'Orbitron', sans-serif;
    text-align: center;
    text-shadow: 0 0 5px #00f2ff;
  }
  
  button {
    background: linear-gradient(45deg, #00f2ff, #0072ff);
    color: #fff;
    border: none;
    padding: 12px 24px;
    font-family: 'Orbitron', sans-serif;
    font-size: 16px;
    cursor: pointer;
    margin-top: 15px;
    border-radius: 30px;
    box-shadow: 0 5px 15px rgba(0, 114, 255, 0.4);
    transition: all 0.3s ease;
  }
  
  button:hover {
    transform: translateY(-2px);
    box-shadow: 0 7px 20px rgba(0, 114, 255, 0.6);
  }
  
  button:active {
    transform: translateY(1px);
    box-shadow: 0 3px 10px rgba(0, 114, 255, 0.4);
  }
  